var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"mdlm-menu","tabindex":"-1","role":"dialog","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-ctm modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_vm._m(0),_c('ul',[_c('li',{staticClass:"dropdown"},[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#","id":"nDML","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" "+_vm._s(_vm.locale)+" ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"nDML"}},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.chLang('ru')}}},[_vm._v("Русский")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.chLang('en')}}},[_vm._v("English")])])]),_c('li',[_c('router-link',{attrs:{"to":"#","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"nav-link",on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_vm._v(_vm._s(_vm.$t('menu.profile'))+" "),_c('i',{staticClass:"fas fa-user"})])]}}])})],1)])]),_c('div',{staticClass:"modal-body"},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('li',{staticClass:"nav-item",attrs:{"id":[_vm.$route.name === 'service' ? 'active' : '']}},[_c('router-link',{attrs:{"to":"/service","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"nav-link",attrs:{"data-dismiss":"modal"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_vm._v(_vm._s(_vm.$t('menu.services')))])]}}])})],1),_c('li',{staticClass:"nav-item",attrs:{"id":[_vm.$route.name === 'booking' ? 'active' : '']}},[_c('router-link',{attrs:{"to":"#","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"modal","data-dismiss":"modal","data-target":"#mdl-future-ok"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_vm._v(_vm._s(_vm.$t('menu.booking')))])]}}])})],1),_c('li',{staticClass:"nav-item",attrs:{"id":[_vm.$route.name === 'doctors' ? 'active' : '']}},[_c('router-link',{attrs:{"to":"/doctors","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"nav-link",attrs:{"data-dismiss":"modal"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_vm._v(_vm._s(_vm.$t('menu.doctors')))])]}}])})],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":"/virtual_tour","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"nav-link",attrs:{"data-dismiss":"modal"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_vm._v(_vm._s(_vm.$t('menu.virtual_tour')))])]}}])})],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":"/documents","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"nav-link",attrs:{"data-dismiss":"modal"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}},[_vm._v(_vm._s(_vm.$t('menu.documentation')))])]}}])})],1),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"modal","data-dismiss":"modal","data-target":"#mdl-contacts"}},[_vm._v(_vm._s(_vm.$t('menu.contacts')))])])])]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"number"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('menu.phone_num')))]),_c('a',{staticClass:"nav-link",attrs:{"href":"#","data-toggle":"modal","data-dismiss":"modal","data-target":"#mdl-call-back"}},[_vm._v(_vm._s(_vm.$t('menu.callback_lnk')))])]),_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('img',{staticClass:"logo",attrs:{"data-dismiss":"modal","src":"/img/logo_light.svg"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate($event)}}})]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"modal","data-target":"#mdlm-menu"}},[_c('i',{staticClass:"fas fa-bars"})])}]

export { render, staticRenderFns }